import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "#fff",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M19.333 22.67v-9.334m-4.666 9.333v-5.333M8 22.669h16v5.334H8zM8 16.668v6h16v-6s3.333-2 3.333-5.333-2.667-4.667-6-4.667c0-1.333-2-4-5.334-4s-5.333 2.667-5.333 4c-2.667 0-6 1.333-6 4.667s3.333 5.333 3.333 5.333"
    }, null, -1)
  ])))
}
export default { render: render }