import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M26.667 29.334H5.333A1.333 1.333 0 0 1 4 28V4a1.333 1.333 0 0 1 1.333-1.333h21.334A1.333 1.333 0 0 1 28 4v24a1.333 1.333 0 0 1-1.333 1.334m-1.334-2.667V5.334H6.667v21.333zM9.333 8h5.334v5.334H9.333zm0 8h13.334v2.667H9.333zm0 5.334h13.334V24H9.333zm8-12h5.334V12h-5.334z" }, null, -1)
  ])))
}
export default { render: render }