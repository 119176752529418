import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m25.964 27.918-1.77-5.81V9.938l1.77-5.856a.76.76 0 0 0-.16-.743.94.94 0 0 0-.334-.252A1 1 0 0 0 25.055 3H6.945a1 1 0 0 0-.419.085.94.94 0 0 0-.338.254.8.8 0 0 0-.151.752l1.78 5.828v12.116l-1.78 5.883a.76.76 0 0 0 .16.743.975.975 0 0 0 .748.34h18.11a1 1 0 0 0 .42-.086.94.94 0 0 0 .338-.254.8.8 0 0 0 .151-.743m-17.777-.632.388-1.283h14.842l.397 1.283zM9.759 9.874a.13.13 0 0 0 0-.065l-.654-2.098h13.8l-.653 2.163v12.244a.2.2 0 0 0 0 .063l.644 2.117h-13.8l.653-2.172V9.892zm13.667-3.877H8.584l-.397-1.283h15.627z" }, null, -1),
    _createElementVNode("path", { d: "m14.409 10.447-2.9 10.213 1.544.445 2.899-10.213zM16.798 11.143 13.9 21.356l1.544.445 2.899-10.213zM19.192 11.83l-2.899 10.213 1.543.445 2.9-10.213z" }, null, -1)
  ])))
}
export default { render: render }